import api from './api_request';
import { CollectionResponse } from "@/models/collection_models";
import { LocalDb } from "@/repository/localdb";
export const AppUserOrdersService = {
    getAppUserOrders: async (pagable: any): Promise<CollectionResponse> => {
        const tokenData = LocalDb.getToken();
        const data = await api.get('/railway/app-user-orders', { params: pagable, headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then(response => {
                return response;
            });
        return data;
    }
}
