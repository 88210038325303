import {stringifyQuery} from "vue-router";

export enum FlightOrderStatus {
    BOOKED = "B", //
    VIOD = "V",
    PAID = "T",
    REFUND = "R",
    INVALID = "INV",
}
export const FlightOrderStatusClass: Record<string, string> = {
    "B": "text-body-warning", //Ожидает выписки
    "V": "text-body-accent", //Отменена
    "T": "text-body-muted", //text-body-muted
    "R": "",
    "INV": "text-body-muted", //Срок оплаты истек
}
