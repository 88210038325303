
import { defineComponent, onMounted, ref } from "vue";
import balancePopup from "./balance_popup.vue";
import { UserService } from "@/services/user_service";

export default defineComponent({
  name: "left_menu",
  components: {
    balancePopup,
  },

  setup() {
    const showBalancePopup = ref(false);
    const balance = ref({});
    onMounted(async () => {
      const data = await UserService.getUserBalance();
      balance.value = data;
    });

    return {
      showBalancePopup,
      balance,
    };
  },
});
