import { reactive, toRefs } from "vue";
import { OrderData } from "@/models/stored_data_models";
import { UserService } from "@/services/user_service";
import { LocalDb } from "@/repository/localdb";
import { AvioneMessage } from "@/constants/error_messages";
import { FlightOrderStatus } from "@/models/flight_order_status";
import { OfferService } from "@/services/offer_service";
import { ResponseStatusType, StatusEnum } from "@/constants/response_status_type";
import Swal from 'sweetalert2'

const state = reactive({
    loading: false,
    showDeleteOrderAlert: false,
    deleteOrderId: {} as number,
    loadingMessage: AvioneMessage.PLEASE_WAIT,
    paidStatus: FlightOrderStatus.PAID,
    bookedOrders: {} as OrderData[],
    paidOrders: {} as OrderData[],
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function profileOrderController() {

    const onLoadStoredData = async function () {
        state.loading = true;
        await UserService.getStoredData().then((storedData) => {
            state.loading = true;
            state.loading = false;
            state.bookedOrders = storedData.orders.filter((s) => s.status !== state.paidStatus).sort((a, b) => (a.orderId < b.orderId ? 1 : -1));
            state.paidOrders = storedData.orders.filter((s) => s.status == state.paidStatus).sort((a, b) => (a.orderId < b.orderId ? 1 : -1));
            LocalDb.saveMyFlightOrders(storedData.orders);
            LocalDb.saveFlightPassengers(storedData.passengers);
        });
    }
    const wantToDeleteOrderCancel = () => {
        state.deleteOrderId = 0;
        state.showDeleteOrderAlert = false;
    }

    const wantToDeleteOrder = (orderId: number) => {
        state.deleteOrderId = orderId;
        state.showDeleteOrderAlert = true;
    }
    const onDeleteOrder = async function (orderId: number) {
        if (state.deleteOrderId > 0) {
            state.loading = true;
            state.deleteOrderId = 0;
            state.showDeleteOrderAlert = false;
            await OfferService.deleteOrder(orderId).then((response) => {
                state.loading = false;
                if (response.status == ResponseStatusType.Ok) {
                    if (response.data.status == StatusEnum.Ok) {
                        LocalDb.deleteMyFlightOrder(orderId);
                        const storedOrders = LocalDb.getMyFlightOrders();
                        if (storedOrders) {
                            state.bookedOrders = storedOrders.filter((s) => s.status !== state.paidStatus).sort((a, b) => (a.orderId < b.orderId ? 1 : -1));
                            state.paidOrders = storedOrders.filter((s) => s.status == state.paidStatus).sort((a, b) => (a.orderId < b.orderId ? 1 : -1));
                        } else {
                            state.bookedOrders = [];
                            state.paidOrders = [];
                        }
                    } else {
                        Swal.fire('', AvioneMessage.COULD_NOT_DELETE_ORDER, 'warning');
                    }
                }
            })
        }
    }

    const onDeleteRailwayOrder = async function (orderId: string) {
        state.loading = true;
        state.deleteOrderId = 0;
        state.showDeleteOrderAlert = false;
        await OfferService.deleteRailwayOrder(orderId).then((response) => {
            state.loading = false;
            if (response.status == ResponseStatusType.Ok) {
                if (response.data.status == StatusEnum.Ok) {
                    LocalDb.deleteMyRailwayOrder(orderId);
                    const storedOrders = LocalDb.getMyFlightOrders();
                    if (storedOrders) {
                        state.bookedOrders = storedOrders.filter((s) => s.orderStatus !== state.paidStatus).sort((a, b) => (a.orderId < b.orderId ? 1 : -1));
                        state.paidOrders = storedOrders.filter((s) => s.orderStatus == state.paidStatus).sort((a, b) => (a.orderId < b.orderId ? 1 : -1));
                    } else {
                        state.bookedOrders = [];
                        state.paidOrders = [];
                    }
                } else {
                    Swal.fire('', AvioneMessage.COULD_NOT_DELETE_ORDER, 'warning');
                }
            }
        })
    }

    return {
        state: toRefs(state),
        onDeleteOrder,
        onLoadStoredData,
        wantToDeleteOrder,
        wantToDeleteOrderCancel,
        onDeleteRailwayOrder
    }
}
