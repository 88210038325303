
import { defineComponent, PropType } from "vue";
import { OrderData } from "@/models/stored_data_models";
import { FlightOrderStatus } from "@/models/flight_order_status";
import { Segment } from "@/models/flight_models";
import profileOrderController from "@/composables/profile_order_list";

export default defineComponent({
  name: "order_list_item",
  props: {
    segment: { type: Object as PropType<Segment> },
    order: { type: Object as PropType<OrderData> },
  },
  data() {
    return {
      paidStatus: FlightOrderStatus.PAID,
      bookedStatus: FlightOrderStatus.BOOKED,
      voidStatus: FlightOrderStatus.VIOD,
    };
  },
  setup() {
    const { state, wantToDeleteOrder } = profileOrderController();
    return { ...state, wantToDeleteOrder };
  },
});
