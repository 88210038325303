
import { defineComponent, PropType } from "vue";
import profileOrderController from "@/composables/profile_order_list";

export default defineComponent({
  name: "OrderListItem",
  props: {
    order: Object,
  },
  emits: ["onUpdateOrdersList"],

  setup(props, { emit }) {
    const {
      state,
      wantToDeleteOrder,
      wantToDeleteOrderCancel,
      onDeleteRailwayOrder,
    } = profileOrderController();
    const deleteRailwayOrder = async (deleteOrderId: string) => {
      await onDeleteRailwayOrder(deleteOrderId);
      emit("onUpdateOrdersList");
    };
    return {
      ...state,
      wantToDeleteOrder,
      wantToDeleteOrderCancel,
      onDeleteRailwayOrder,
      deleteRailwayOrder,
    };
  },
});
